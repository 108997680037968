<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <p class="text text_white">Customer details have been successfully updated.
        <span class="modal__close" @click="close">
         <svg-icon name="times"/>
      </span>
      </p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="close">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SuccessChangeCustomer",

    methods: {
      close() {
        this.$store.commit('customerDetails/successModalOpen', false);
      },
    }
  }
</script>

<style scoped>

</style>
